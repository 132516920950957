























































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import './scss/PopupEditResponsiblePerson.scss';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { IDocument } from "@store/modules/project-documents/Interfaces";
import { IIndividualMember, IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSAboutProject = namespace('storeAboutProject');
const NSProject = namespace('storeProject');
const NSProjectDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupEditResponsiblePerson',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    }
})

export default class PopupEditResponsiblePerson extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @Prop({}) selectedItem!: any;

    @NSAboutProject.Action(AboutProjectActions.A_EDIT_RESPONSIBLE_PERSON) editResponsiblePerson!: (payload) => Promise<void>;
    @NSProject.State((state: IProjectState) => state.memberRoles) memberRoles!: IMemberRole[];
    @NSProject.Getter('projectData') private projectData!: IProject;
    @NSProjectDocuments.Getter('documents') documents!: IDocument[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSAboutProject.Getter('individualMembers') individualMembers!: IIndividualMember[];

    @Watch('selectedItem')
    onChangeSelectedItem() {
        this.fields.name = this.selectedItem.name;
        this.fields.position = this.selectedItem.position;
        this.fields.role = this.selectedItem.role.id;
        this.fields.company = this.selectedItem.juristicPersonId;
        this.fields.isMain = this.selectedItem.isMain;
        this.fields.activationDate = this.selectedItem?.activationDate
            ? this.selectedItem.activationDate?.split('T')[0]
            : '';
        this.fields.appointmentDate = this.selectedItem?.activationDate
            ? this.selectedItem.appointmentDate?.split('T')[0]
            : '';
        if (this.selectedItem.document) {
            this.fields.document = {
                uuid: this.selectedItem.document.id,
                value: this.selectedItem.document.name,
                label: this.selectedItem.document.name,
            };
        }
        this.showIsMainError = false;
    }

    realtimeValidation = false;
    btnDisabled = false;
    loading = false;

    fields = {
        name: '',
        position: '',
        role: '',
        appointmentDate: '',
        activationDate: '',
        company: '',
        isMain: false,
        document: {} as any,
        file: null,
    };

    selectedCompanyText: string = '';

    errors = {
        name: false,
        position: false,
        file: false,
    }

    showIsMainError = false;

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    get companiesList(): any[] {
        const output: any[] = [];
        this.juristicEntities.forEach((item: IJuristicEntity) => {
            output.push({
                uuid: item.id,
                label: item.title,
                value: item.title,
            })
        })
        return output;
    }

    get titleForSelectCompany() {
        if (this.fields.company) {
            const companyItem = this.companiesList.filter(item => item.uuid === this.fields.company);
            if (companyItem.length) {
                return companyItem[0].value;
            }
        }
        return 'Выберите компанию';
    }

    get documentsOtherList() {
        return this.documents
            .filter(document => document.category === "Прочее")
            .map(doc => ({
                uuid: doc.uuid,
                label: doc.title,
                value: doc.title,
            }))
    }

    get titleForSelectRole() {
        if (this.fields.role) {
            const roleItem = this.memberRoles.filter(item => item.uuid === this.fields.role);
            if (roleItem.length) {
                return roleItem[0].value;
            }
        }
        return 'Выберите роль';
    }

    get titleForSelectDocument() {
        if (this.fields.document?.uuid) {
            const document = this.documentsOtherList.find(document => document?.uuid === this.fields.document.uuid);
            if (document) {
                return document.value;
            }
        }
        return 'Выберите документ';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get individualMembersObj() {
        return this.individualMembers.reduce((obj, item) => ({ ...obj, [item.id]: item}), {});
    }

    get isMainDisabled() {
        if (!this.selectedItem || this.selectedItem.isMain) return false;
        return !!this.individualMembersObj[this.selectedItem.id]?.workPlace;
    }

    selectRole(e) {
        this.fields.role = e.uuid;
        this.checkValidation();
    }

    selectCompany(e) {
        this.fields.company = e.uuid;
        this.selectedCompanyText = e.value;
        this.checkValidation();
    }

    toggleMainCompany() {
        if (this.isMainDisabled) {
            this.showIsMainError = true;
        } else {
            this.fields.isMain = !this.fields.isMain;
        }
    }

    selectDocument(document) {
        this.fields.document = document;
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        this.loading = false;
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateForm();
        }
    }

    validateForm() {
        this.errors.name = !this.fields.name;
        this.errors.position = !this.fields.position;
    }

    onSuccess() {
        this.realtimeValidation = true;
        this.btnDisabled = true;
        this.validateForm();
        if (!this.errorExists) {
            this.sendForm();
            this.realtimeValidation = false;
        }
        this.btnDisabled = false;
    }

    sendForm() {
        const formData = new FormData();
        const changedItem = JSON.parse(JSON.stringify(this.selectedItem));
        formData.append('appId', this.projectId);
        formData.append('id', this.selectedItem.id);
        formData.append('entityId', this.selectedItem.juristicPersonId);
        formData.append('roleId', this.selectedItem.role.id);
        formData.append('isMain', JSON.stringify(this.fields.isMain));
        if (this.fields.name) {
            formData.append('name', this.fields.name);
            changedItem.name = this.fields.name;
        }
        if (this.fields.position) {
            formData.append('position', this.fields.position);
            changedItem.position = this.fields.position;
        }
        if (this.fields.role) {
            formData.append('newRoleId', this.fields.role);
            changedItem.role = this.memberRoles
                .filter(item => item.uuid === this.fields.role)
                .map(role => ({ id: role.uuid, name: role.label }))[0];
        }
        if (this.fields.company) {
            formData.append('newEntityId', this.fields.company);
            changedItem.juristicPersonId = this.fields.company;
        }
        if (this.fields.activationDate) {
            formData.append('activationDate', this.fields.activationDate);
            changedItem.activationDate = this.fields.activationDate;
        }
        if (this.fields.appointmentDate) {
            formData.append('employmentDate', this.fields.appointmentDate);
            changedItem.appointmentDate = this.fields.appointmentDate;
        }
        if (this.fields && this.fields.file && this.fields!.file![0]) {
            formData.append('file', this.fields!.file![0], this.fields!.file![0]['name']);
        }
        if (this.fields?.document?.uuid) {
            formData.append('activationDocumentId', this.fields.document.uuid);
            changedItem.docuement = this.documentsOtherList.filter(item => item.uuid === this.fields.document.uuid)[0];
        }
        this.editResponsiblePerson(formData)
            .then(() => {
                this.$emit('confirm-edit-responsible-person');
                this.loading = true;
        });
    }

    resetFields() {
        this.fields = {
            name: this.selectedItem?.name || '',
            position: this.selectedItem?.position || '',
            role: this.selectedItem?.role.id || '',
            activationDate: '',
            appointmentDate: '',
            company: '',
            isMain: false,
            document: {} as any,
            file: null,
        }
        this.errors = {
            name: false,
            position: false,
            file: false,
        }
        this.showIsMainError = false;
    }

    closeAndReset() {
        this.closePopup();
        this.resetFields();
    }

    onFileChange(e) {
        this.fields.file = e;
    }
}
